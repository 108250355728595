<script setup lang="ts">
defineProps<{
    hasJobAdServices?: boolean;
    hasTalentServices?: boolean;
    horizontal?: boolean;
}>();

const runtimeConfig = useRuntimeConfig();
const content_Url = runtimeConfig.public.karriere.content.url;
</script>

<template>
    <div
        class="flex flex-col justify-between gap-4 rounded p-6 lg:px-6 lg:py-8"
    >
        <div
            :class="`grid grid-cols-1 items-center gap-5 ${
                horizontal ? 'md:grid-cols-[1fr,2fr] xl:grid-cols-1' : ''
            }`"
        >
            <NuxtImg
                src="/illustrations/q-and-a.webp"
                format="webp"
                alt="Illustration einer Frau am Laptop mit Sprechblasen, die ein Fragezeichen und ein Ausrufezeichen enthalten, symbolisiert Nachdenken und Problemlösung."
                sizes="200px lg:270px"
                class="mx-auto max-w-[270px] object-contain"
            />
            <div class="flex flex-col gap-6">
                <div class="flex flex-col gap-2">
                    <JamBaseText variant="large" class="lg:hidden">
                        Welches Stelleninserat ist das richtige für Sie?
                    </JamBaseText>
                    <JamBaseText variant="xl" class="hidden lg:block">
                        Welches Stelleninserat ist das richtige für Sie?
                    </JamBaseText>

                    <JamBaseText>
                        Unser Produktberater hilft Ihnen bei der
                        Entscheidungsfindung.*
                    </JamBaseText>
                </div>
                <JamBaseButton
                    variant="dark-gray-outline"
                    :to="`${content_Url}/hr/stelleninserate/produktberater`"
                    target="_blank"
                    full-width
                >
                    Jetzt Produktberater starten
                </JamBaseButton>
            </div>
        </div>
        <CommExcentosLegalText class="text-xs leading-4" />
    </div>
</template>
